<template>
  <div>
    <label>{{ label }}</label>
    <div
      :class="[
        `flex items-center flex-input justify-between px-2 bg-ghostWhite'
        ${disabled ? 'disabled-input' : ''} `,
      ]"
      style=" background-color: #F7F7FF;"
    >
      <Icon
        class-name="text-darkPurple h-full mx-auto self-center ml-2"
        size="xsm"
        icon-name="icon-search"
      />
      <input
        :class="classes"
        :placeholder="`--${placeholder}--`"
        ref="data"
        :value="value"
        @input="update()"
        :disabled="disabled"
      >
      <Icon
        class-name="text-darkPurple h-full mx-auto self-center mr-2"
        size="xs"
        icon-name="sort_size"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CloudenlyText",
  data() {
    return {
      cancel: false,
    };
  },
  methods: {
    update() {
      this.$emit("input", this.$refs.data.value);
      if (this.$refs.data.value) {
        this.cancel = true;
      } else {
        this.cancel = false;
      }
    },

    clear() {
      this.$refs.data.value = "";
      this.$emit("input", this.$refs.data.value);
      this.cancel = false;
    },
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String || Number,
      default: "",
    },
  },
  computed: {
    classes() {
      return ["cloudenly-input h-10", this.variant];
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
* {
  font-family: "Nunito Sans";
}
label {
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333333;
  margin-bottom: 7px;
}
.close {
  width: auto;
  height: 18px;
}
.cloudenly-input {
  outline: none;
  border: none;
  height: 40px !important;
  padding: 10px 15px;
  width: 90%;
  border-radius: 5px;
  background-color: #F7F7FF;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.flex-input {
  padding: 2.5px 10px;
  outline: none;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: space-between;
}
.disabled-input:disabled {
  background: #eeeeee !important;
  color: #878e99 !important;
}

.flex-input:focus-within {
  /* border: 1px solid #321c3b; */
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.flex-input:focus {
  /* border: 1px solid #321c3b; */
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}

.error {
  border: 1px solid #ea3c53 !important;
  color: #ea3c53 !important;
}

.large {
  width: 240px;
}
.medium {
  width: 192px;
}
.small {
  width: 106px;
}
</style>
